<template>
  <LiefengContent>
    <template v-slot:title>评估记录</template>
    <template v-slot:toolsbarRight>
      <Button @click="addRecord" type="primary" icon="md-add"
        >新增服务评估</Button
      >
      <Button type="warning" icon="md-eye" @click="drawerStatus = true"
        >查看服务对象信息</Button
      >
      <Button
        type="error"
        @click="$router.push('/archivesassessment')"
        icon="ios-arrow-back"
        >返回</Button
      >
      <Drawer width="320" title="服务对象信息" v-model="drawerStatus">
        <div>档案编号：{{ serviceObj.archiveNum }}</div>
        <div>服务对象：{{ serviceObj.name }}</div>
        <div>所属社区：{{ serviceObj.orgName }}</div>
        <div>手机号：{{ serviceObj.mobile }}</div>
        <div>分类：{{ serviceObj.difficultType }}</div>
        <div>分级：{{ serviceObj.difficultLevel }}</div>
      </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>

      <!-- 登记服务/修改 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeStatus"
        width="550"
        height="500px"
        @input="addChangeStatusFn"
      >
        <template v-slot:contentarea>
          <Form
            ref="addChangeForm"
            :model="addChangeData"
            :label-colon="true"
            :rules="addChangeValidator"
          >
            <FormItem label="评估人" :label-width="85" prop="assessor">
              <Input
                v-model.trim="addChangeData.assessor"
                :maxlength="20"
              ></Input>
            </FormItem>
            <FormItem label="评估时间" :label-width="85" prop="assessDate">
              <DatePicker
                type="date"
                v-model="addChangeData.assessDate"
                placeholder="请选择评估时间"
              ></DatePicker>
            </FormItem>
            <FormItem
              label="服务成效（服务对象问题改善情况，志愿者参与成效，社会资源链接情况等）"
              prop="seviceEffect"
            >
              <Input
                type="textarea"
                style="width: 100%"
                :rows="4"
                show-word-limit
                v-model="addChangeData.seviceEffect"
                :maxlength="400"
                placeholder="请输入服务成效，不超过400字"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              addChangeStatusFn(false);
            "
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveAddChange">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/assessmentRecord')
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent";
import { format } from "@/utils/formatTime";
export default {
  data() {
    return {
      talbeColumns: [
        {
          title: "服务成效",
          key: "seviceEffect",
          minWidth: 200,
          align: "center",
        },
        {
          title: "评估人",
          key: "assessor",
          minWidth: 150,
          align: "center",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          minWidth: 150,
          align: "center",
        },
        {
          title: "评估时间",
          key: "assessDate",
          minWidth: 150,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 240,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "20px",
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.id;
                      this.changeRecord();
                    },
                  },
                },
                "修改评估"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.delRow(params.row.id);
                    },
                  },
                },
                "删除评估记录"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      loading: false,
      pageSize: 20,

      //查看抽屉
      drawerStatus: false,
      serviceObj: {},

      //新增/修改
      cancelModelTip: true, //true代表关闭时弹窗提示
      addChangeStatus: false,
      addChangeTitle: "",
      addChangeData: {},
      infoId: "",
      addChangeIden: true, //true代表新增
      addChangeValidator: {
        assessor: [
          { required: true, message: "请输入评估人", trigger: "blur" },
        ],
        assessDate: [
          {
            required: true,
            type: "date",
            message: "请选择评估时间",
            trigger: "change",
          },
        ],
        seviceEffect: [
          { required: true, message: "请输入服务成效", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 修改
    changeRecord() {
      this.$refs.addChangeForm.resetFields();
      this.addChangeTitle = "修改服务评估";
      this.addChangeIden = false;
      this.addChangeData = {};
      this.$get('/archives/api/pc/serviceAssess/queryById',{
        id: this.infoId
      }).then(res => {
        if(res.code == 200 && res.data) {
          this.addChangeData = res.data;
          this.addChangeData.assessDate = new Date(this.addChangeData.assessDate);
          this.addChangeStatus = true;
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
    },
    //新增
    addRecord() {
      this.$refs.addChangeForm.resetFields();
      this.addChangeTitle = "新增服务评估";
      this.addChangeData = {
        assessor: parent.vue.loginInfo.userinfo.realName,
        assessDate: new Date()
      };
      this.addChangeIden = true;
      this.addChangeStatus = true;
    },
    saveAddChange() {
      this.$refs.addChangeForm.validate((status) => {
        if (status) {
          this.cancelModelTip = false;
          if (this.addChangeIden) {
            //新增
            this.$post("/archives/api/pc/serviceAssess/add", {
              ...this.addChangeData,
              archiveId: this.$route.query.id,
              assessDate: this.$core.formatDate(
                new Date(this.addChangeData.assessDate),
                "yyyy-MM-dd"
              ),
              staffName: parent.vue.loginInfo.userinfo.realName,
            })
              .then((res) => {
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "保存成功",
                  });
                  this.addChangeStatus = false;
                  this.hadlePageSize({
                    page: this.page,
                    pageSize: this.pageSize,
                  });
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc,
                  });
                }
              })
              .catch((err) => {
                this.$Message.error({
                  background: true,
                  content: "保存失败",
                });
              });
          } else {
            //修改
            this.$post("/archives/api/pc/serviceAssess/update", {
              ...this.addChangeData,
              assessDate: this.$core.formatDate(
                new Date(this.addChangeData.assessDate),
                "yyyy-MM-dd"
              ),
            }).then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "保存成功",
                });
                this.addChangeStatus = false;
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize,
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            });
          }
        }
      });
    },
    addChangeStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addChangeStatus = status;
          },
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    //删除记录
    delRow(id) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>您正在删除信息，是否继续</p>",
        onOk: () => {
          this.$post("/archives/api/pc/serviceAssess/delete", {
            id: id,
          }).then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "删除成功",
              });
              this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
              });
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          });
        },
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$post("/archives/api/pc/serviceAssess/queryAssessByPage", {
        page: obj.page,
        pageSize: obj.pageSize,
        archiveId: this.$route.query.id,
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.serviceObj = {
            archiveNum: res.data.archiveNum,
            name: res.data.name,
            orgName: res.data.orgName,
            mobile: res.data.mobile,
          };
          switch (res.data.difficultType) {
            case "1":
              this.serviceObj.difficultType = "低保低收入对象";
              break;
            case "2":
              this.serviceObj.difficultType = "特困人员";
              break;
            case "3":
              this.serviceObj.difficultType = "留守老年人";
              break;
            case "4":
              this.serviceObj.difficultType = "独居孤寡老人";
              break;
            case "5":
              this.serviceObj.difficultType = "困境儿童";
              break;
            case "6":
              this.serviceObj.difficultType = "贫困重度残疾人";
              break;
            default:
              this.serviceObj.difficultType = "其他";
              break;
          }
          switch (res.data.difficultLevel) {
            case "1":
              this.serviceObj.difficultLevel = "一级";
              break;
            case "2":
              this.serviceObj.difficultLevel = "二级";
              break;
            case "3":
              this.serviceObj.difficultLevel = "三级";
              break;
            case "4":
              this.serviceObj.difficultLevel = "四级";
              break;
          }
          if (
            res.data.serviceAssessPages.code == 200 &&
            res.data.serviceAssessPages.dataList
          ) {
            res.data.serviceAssessPages.dataList.map((item) => {
              if (item.gmtCreate) {
                item.gmtCreate = format(
                  new Date(item.gmtCreate),
                  "yyyy-MM-dd HH:mm:ss"
                );
              }
              if (item.assessDate) {
                item.assessDate = format(
                  new Date(item.assessDate),
                  "yyyy-MM-dd"
                );
              }
            });
            this.tableData = res.data.serviceAssessPages.dataList;
            this.total = res.data.serviceAssessPages.maxCount;
            this.page = res.data.serviceAssessPages.currentPage;
            this.pageSize = res.data.serviceAssessPages.pageSize;
          }
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
        this.loading = false;
      });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.ivu-drawer-body {
  div {
    margin-bottom: 14px;
  }
}
/deep/.ivu-modal-close {
  display: none;
}
</style>